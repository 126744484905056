import {attr, hasMany, belongsTo} from "spraypaint";
import ApplicationRecord from "./baseModel";
import UserModel from "./userModel";
import CityModel from "./cityModel";

const MarketplaceModel = ApplicationRecord.extend({
	static: { jsonapiType: "marketplaces" },
	attrs: {
		id: attr(),
		name: attr(),
		description: attr(),
		logo: attr(),
		logoUrl: attr(),
		image: attr(),
		imageUrl: attr({ persist: false }),
		catalogBackground: attr(),
		catalogBackgroundUrl: attr({ persist: false }),
		unverifiedLogo: attr(),
		unverifiedLogoUrl: attr({ persist: false }),
		unverifiedImage: attr(),
		unverifiedImageUrl: attr({ persist: false }),
		unverifiedCatalogBackground: attr(),
		unverifiedCatalogBackgroundUrl: attr({ persist: false }),
		status: attr(),
		unverifiedChanges: attr(),
		hasUnverifiedChanges: attr(),
		comment: attr(),

		unpublish: attr(),
		publish: attr(),
		rejectChanges: attr(),
		approveChanges: attr(),
		markForVerification: attr(),

		flags: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),
		owner: belongsTo(UserModel),
		city: belongsTo(CityModel),
		marketplaceTags: hasMany(),
		categories: hasMany(),
		products: hasMany(),
		marketplaceLocations: hasMany(),
	}
})

export default MarketplaceModel