import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Badge, Button, Spinner} from "react-bootstrap";
import MerchantPageWrapper from "../../../components/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {getMarketplace} from "../../../api/marketplaceAPI";

const MarketplacePage = () => {
	const { marketplaceId } = useParams();
	const navigate = useNavigate();
	const [marketplace, setMarketplace] = useState(null);
	const [viewData, setViewData] = useState({
		name: '',
		description: '',
		logo: ''
	});

	const getCurrentMarketplace = async () => {
		await getMarketplace(marketplaceId)
		.then(res => {
			setMarketplace(res);

			if (res.status === 'draft') {
				setViewData({
					name: res?.unverifiedChanges?.name || res?.name,
					description: res?.unverifiedChanges?.description || res?.description,
					logo: res?.unverifiedLogoUrl || res.logoUrl
						? `${process.env.REACT_APP_BASE_URL}${res?.unverifiedLogoUrl || res.logoUrl}`
						: null,
				})
			} else {
				setViewData({
					name: res.name,
					description: res.description,
					logo: res.logoUrl ? `${process.env.REACT_APP_BASE_URL}${res.logoUrl}` : null,
				})
			}
		})
	};

	useEffect(() => {
		getCurrentMarketplace();
	}, []);

	const statusBuilder = (status) => {
		switch (status) {
			case 'draft':
				return <Badge bg="danger">Черновик бизнеса</Badge>;
			case 'verification':
				return <Badge bg="warning" text="dark">В процессе верификации</Badge>;
			case 'published':
				return <Badge bg="success">Бизнес активен</Badge>;
		}
	};

	const moveToDrafthandler = async () => {
		marketplace.unpublish = true;
		await marketplace.save()
		.then(() => {
			getCurrentMarketplace()
		})
	};

	const moveToVerification = async () => {
		marketplace.markForVerification = true;
		await marketplace.save()
		.then(() => {
			getCurrentMarketplace()
		})
	}

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				{marketplace
					? (
						<>
							<div className="flex-space-between">
								<h1>{viewData.name}</h1>
								<div>
									Статус проверки: {statusBuilder(marketplace.status)}
								</div>
							</div>
							{viewData.logo && (
								<img
									src={viewData.logo || ''}
									alt={viewData.name}
									style={{width: '150px', marginBottom: '20px'}}
								/>
							)}
							<p>{viewData.description}</p>
							<Button
								variant="dark"
								onClick={() => {navigate(`/merchant/${marketplaceId}/marketplace/edit`)}}
							>Редактировать</Button>
							{marketplace.status === 'draft' ? (
								<Button
									variant="dark"
									className="marginLeft20"
									onClick={() => moveToVerification()}
								>Отправить на верификацию</Button>
							) : (
								<Button
									variant="dark"
									className="marginLeft20"
									onClick={() => moveToDrafthandler()}
								>Перенести в черновики</Button>
							)}
						</>
					) : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default MarketplacePage