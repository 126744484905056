import {attr, belongsTo} from "spraypaint";
import ApplicationRecord from "./baseModel";
import MarketplaceModel from './marketplaceModel';

const ProductModel = ApplicationRecord.extend({
	static: { jsonapiType: "products" },
	attrs: {
		id: attr(),
		name: attr(),
		description: attr(),
		weight: attr(),
		composition: attr(),
		price: attr(),
		discountPrice: attr(),
		partialPaymentWithPoints: attr(),
		fullPaymentWithPoints: attr(),
		image: attr(),
		imageUrl: attr({ persist: false }),
		unverifiedImage: attr(),
		unverifiedImageUrl: attr({ persist: false }),
		status: attr(),
		isPublished: attr(),
		unverifiedChanges: attr(),
		hasUnverifiedChanges: attr(),
		comment: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),
		unpublish: attr(),
		publish: attr(),
		rejectChanges: attr(),
		approveChanges: attr(),
		markForVerification: attr(),
		category: belongsTo(), // сюда вписать нужную модель
		marketplace: belongsTo(MarketplaceModel),
		cartItem: belongsTo()
	}
})

export default ProductModel