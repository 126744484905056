import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Card, Container, Spinner } from 'react-bootstrap'
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {USER} from "../../../helpers/constants/localStorageConstants";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import {getUserMarketplaces} from "../../../api/marketplaceAPI";
import './mainPage.scss';

const MainPage = () => {
	const user = JSON.parse(localStorage.getItem(USER));
	const navigate = useNavigate();
	const [ marketplaces, setMarketplaces ]= useState([]);

	useEffect(() => {
		getUserMarketplaces(user.id)
		.then(res => {
			setMarketplaces(res)
		})
	},[]);

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<Container className="marginTop40 marginBottom40">
				<h1 className="marginBottom60">
					 Выберите бизнес и перейдите на его страницу
				</h1>

				{marketplaces.length > 0 ? (
					<>
						<div className="block marginBottom20">
							<h1 className="marginBottom20">Черновики бизнесов</h1>
							<div className="grid3x">
								<Button variant="dark" onClick={() => {navigate("/merchant/marketplace/create");}}>
									<h5>Добавить новый бизнес</h5>
								</Button>
								{marketplaces.filter(i => i.status === 'draft').map(market => (
									<Card key={market?.unverifiedChanges?.name || market?.name}>
										<Card.Img variant="top" src={market.logo} />
										<Card.Body>
											<Card.Title><b>{market?.unverifiedChanges?.name || market?.name}</b></Card.Title>
											<Card.Text>{market?.unverifiedChanges?.description || market?.description}</Card.Text>
											<Button variant="dark" onClick={() => {navigate(`/merchant/${market.id}/marketplace`);}}>
												Перейти на страницу бизнеса
											</Button>
										</Card.Body>
									</Card>
								))}
							</div>
						</div>
						<div className="block marginBottom20">
							<h1 className="marginBottom20">Ожидающие верификации</h1>
							<div className="grid3x">
								{marketplaces.filter(i => i.status === 'verification').map(market => (
									<Card key={market?.unverifiedChanges?.name || market.name}>
										<Card.Img variant="top" src={market.logo} />
										<Card.Body>
											<Card.Title><b>{market?.unverifiedChanges?.name || market?.name}</b></Card.Title>
											<Card.Text>{market?.unverifiedChanges?.description || market?.description}</Card.Text>
											<Button variant="dark" onClick={() => {navigate(`/merchant/${market.id}/marketplace`);}}>
												Перейти на страницу бизнеса
											</Button>
										</Card.Body>
									</Card>
								))}
							</div>
						</div>
						<div className="block marginBottom20">
							<h1 className="marginBottom20">Опубликованные бизнесы</h1>
							<div className="grid3x">
								{marketplaces.filter(i => i.attributes.status === 'published').map(market => (
									<Card key={market.name}>
										<Card.Img variant="top" src={market.logo} />
										<Card.Body>
											<Card.Title><b>{market.name}</b></Card.Title>
											<Card.Text>{market.description}</Card.Text>
											<Button variant="dark" onClick={() => {navigate(`/merchant/${market.id}/marketplace`);}}>
												Перейти на страницу бизнеса
											</Button>
										</Card.Body>
									</Card>
								))}
							</div>
						</div>
					</>
				) : (
					<Spinner style={{ position: "fixed", top: '50%', left: '50%'}} />
				)}
			</Container>
		</ProtectedWrapper>
	)
}

export default MainPage;