import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Table, Button, Spinner, Form, InputGroup, Badge} from "react-bootstrap";
import {getOrder} from "../../../api/orderAPI";
import MerchantPageWrapper from "../../../components/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {
	OREDER_STATUSES,
	AWAITING_PROCCESING,
	PROCESSING,
	AWAITING_PICKUP,
	PICKEDUP_COURIER,
	DELIVERED
} from "../../../helpers/constants/orderStatus";

const OrderPage = () => {
	const { marketplaceId, orderId } = useParams();
	const navigate = useNavigate();
	const [order, setOrder] = useState([]);
	//
	const getCurrentOrder = async () => {
		await getOrder(orderId)
		.then(res => {
			setOrder(res);
		})
	}

	useEffect(() => {
		getCurrentOrder();
	}, []);

	const startProcessing = async () => {
		order.startProcessing = true;
		await order.save();
		getCurrentOrder();
	}

	const completeProcessing = async () => {
		order.completeProcessing = true;
		await order.save();
		getCurrentOrder();
	}

	const giveToReceiver = async () => {
		order.giveToReceiver = true;
		await order.save();
		getCurrentOrder();
	}

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				{order
					? (
						<>
							<Button
								variant="dark"
								onClick={() => {navigate(`/merchant/${marketplaceId}/orders`)}}
								className="marginBottom20"
							>Назад</Button>
							<h1 className="marginBottom20">Заказ: {order.publicUid}</h1>
							<div className="flex-space-between marginBottom40">
								<div>
									Текущий статус заказа:
									<Badge bg={(order.status === DELIVERED || order.status === PICKEDUP_COURIER) ? "success" : "warning"}>
										{OREDER_STATUSES[order.status]?.translate || ''}
									</Badge>
								</div>
								{order.status === AWAITING_PROCCESING && (
									<Button
										variant="dark"
										onClick={() => startProcessing()}
									>Начать сборку заказа</Button>
								)}
								{order.status === PROCESSING && (
									<Button
										variant="dark"
										onClick={() => completeProcessing()}
									>Заказ собран</Button>
								)}
								{order.status === AWAITING_PICKUP && (
									<Button
										variant="dark"
										onClick={() => giveToReceiver()}
									>Заказ выдан</Button>
								)}
							</div>
							{(order.orderItems && order.orderItems.length > 0) && (
								<>
									<h3>Состав заказа:</h3>
									<div className="marginBottom40">
										{order.orderItems.map((item, idx) => (
											<div>{`${idx + 1}) ${item.product.name || 'Имя не найдено'} - Количество ${item.quantity}шт.`}</div>
										))}
									</div>
								</>
							)}
							{order.comment && (
								<>
									<h3>Комментарий к заказу:</h3>
									<div className="marginBottom40">
										{order.comment}
									</div>
								</>
							)}
							{order.statusComment && (
								<>
									<h3>Комментарий к отмене заказа:</h3>
									<div className="marginBottom40">
										{order.statusComment}
									</div>
								</>
							)}
						</>
					)
					 : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default OrderPage