import SparkMD5 from "spark-md5";
import {TOKEN} from "./constants/localStorageConstants";

export const attachHandler = (file, resolveHandler) => {
	let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
	let chunkSize = 2097152;
	let chunks = Math.ceil(file.size / chunkSize);
	let currentChunk = 0;
	let spark = new SparkMD5.ArrayBuffer();
	let fileReader = new FileReader();

	fileReader.onload = function (e) {
		spark.append(e.target.result);
		currentChunk++;

		if (currentChunk < chunks) {
			loadNext();
		} else {
			resolveHandler(file, btoa(spark.end(true)));
		}
	};

	function loadNext() {
		let start = currentChunk * chunkSize;
		let end = start + chunkSize >= file.size ? file.size : start + chunkSize;

		fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
	}

	loadNext();
};

export const uploadHandler = async (data) => {
	const token = localStorage.getItem(TOKEN);

	return await fetch(`${process.env.REACT_APP_BASE_URL}/api/active_storage/direct_uploads`, {
		method: "POST",
		credentials: "include",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({
			blob: {
				filename: data?.file?.name,
				byte_size: data?.file?.size,
				content_type: data?.file?.type,
				checksum: data?.checkSum,
			},
		}),
	})
	.then((res) => res.json())
	.then(async (res) => {
		const signedId = res.signed_id;

		return await fetch(res.direct_upload.url, {
			method: "PUT",
			credentials: "include",
			headers: {
				...res.direct_upload.headers,
			},
			body: data.file,
		})
		.then((res) => {
			return signedId;
		})
		.catch((err) => {
			console.error(err);
		});
	})
	.catch((err) => {
		console.error(err);
	});
};