import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import './mainPage.scss';

const MainPage = () => {
	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			hello "{ADMIN_ROLE}"
		</ProtectedWrapper>
	)
}

export default MainPage;