import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Spinner, Form, InputGroup} from "react-bootstrap";
import MerchantPageWrapper from "../../../components/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {getMarketplace} from "../../../api/marketplaceAPI";
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import {attachHandler, uploadHandler} from "../../../helpers/directUpload";

const EditMarketplacePage = () => {
	const { marketplaceId } = useParams();
	const navigate = useNavigate();
	const [marketplace, setMarketplace] = useState(null);
	const [formData, setFormData] = useState({
		name: '',
		description: '',
	});
	const [logo, setLogo] = useState('');
	const [background, setBackground] = useState('');
	const [mainImage, setMainImage] = useState('');

	useEffect(() => {
		getMarketplace(marketplaceId)
		.then(res => {
			setMarketplace(res);
			if (res?.status === 'draft') {
				setFormData({
					name: res?.unverifiedChanges?.name || '',
					description: res?.unverifiedChanges?.description || '',
				})
			} else {
				setFormData({
					name: res?.name || '',
					description: res?.description || '',
				})
			}

			setLogo(res.logoUrl || res.unverifiedLogoUrl ? `${process.env.REACT_APP_BASE_URL}${res.unverifiedLogoUrl || res.logoUrl}` : '');
			setBackground(res.catalogBackgroundUrl || res.unverifiedCatalogBackgroundUrl ? `${process.env.REACT_APP_BASE_URL}${res.unverifiedCatalogBackgroundUrl || res.catalogBackgroundUrl}` : '');
			// TODO: поправить после того как КОРСЫ пофиксятся
			// setMainImage(res.imageUrl ? res.imageUrl : '');
		})
	}, []);

	const changeHandler = (name, value) => {
		setFormData(p => ({...p, [name]: value}))
	};

	const attachLogoHandler = (file) => {
		attachHandler(file, (f, c) => setLogo({ file: f, checkSum: c }));
	};

	const attachBackgroundHandler = (file) => {
		attachHandler(file, (f, c) => setBackground({ file: f, checkSum: c }));
	};

	const attachMainHandler = (file) => {
		attachHandler(file, (f, c) => setMainImage({ file: f, checkSum: c }));
	};

	const submitHandler = async () => {
		marketplace.unverifiedChanges = {
			name: formData.name,
			description: formData.description
		};

		if (logo?.file) {
			marketplace.unverifiedLogo = logo && (await uploadHandler(logo));
		}

		if (background?.file) {
			marketplace.unverifiedCatalogBackground = background && (await uploadHandler(background));
		}

		if (mainImage?.file) {
			marketplace.unverifiedImage = mainImage && (await uploadHandler(mainImage));
		}

		await marketplace.save({ with: "city.id"})
		.then(async () => {
			window.location.pathname = `/merchant/${marketplaceId}/marketplace`;
		})
	};

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				{marketplace
					? (
						<div>
							<Button
								variant="dark"
								onClick={() => {navigate(`/merchant/${marketplaceId}/marketplace`)}}
								className="marginBottom20"
							>Назад</Button>
							<h1 className="marginBottom20">Редактирование</h1>
							<InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon1">Название</InputGroup.Text>
								<Form.Control
									placeholder="Название"
									aria-label="Название"
									aria-describedby="basic-addon1"
									value={formData.name}
									onChange={e => changeHandler('name', e.target.value)}
								/>
							</InputGroup>
							<InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon1">Описание</InputGroup.Text>
								<Form.Control
									placeholder="Описание"
									aria-label="Описание"
									aria-describedby="basic-addon1"
									value={formData.description}
									onChange={e => changeHandler('description', e.target.value)}
								/>
							</InputGroup>

							<DropZoneUploader
								preview={logo}
								acceptPreviewHandler={(f) => {
									if (f) attachLogoHandler(f[0]);
								}}
								className="marginBottom20"
								text="Прикрепите логотип вашего бизнеса"
							/>
							<DropZoneUploader
								preview={background}
								acceptPreviewHandler={(f) => {
									if (f) attachBackgroundHandler(f[0]);
								}}
								className="marginBottom20"
								text="Прикрепите фон для вашего каталога"
							/>
							<DropZoneUploader
								preview={mainImage}
								acceptPreviewHandler={(f) => {
									if (f) attachMainHandler(f[0]);
								}}
								className="marginBottom20"
								text="Прикрепите ваше основное изображение"
							/>
							<Button
								variant="dark"
								onClick={() => submitHandler()}
								className="marginBottom20"
								disabled={!formData.name || !formData.description}
							>Сохранить</Button>
						</div>
					) : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default EditMarketplacePage