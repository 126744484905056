import OrderModel from "../models/orderModel";

const getAllOrders = async () => {
	const res = await OrderModel.all();
	return res.data;
};

const getOrdersForMarketplace = async (marketplaceId) => {
	const res = await OrderModel.where({marketplaceId: marketplaceId}).all();
	return res.data;
};

const getFilterOrdersForMarketplace = async (marketplaceId, status) => {
	const res = await OrderModel.where({marketplaceId: marketplaceId, status: status}).all();
	return res.data;
};

const searchOrdersByNumber = async (marketplaceId, orderNumber) => {
	const res = await OrderModel.where({marketplaceId: marketplaceId, publicUid: {match: orderNumber}}).all();
	return res.data;
};

const getOrder = async (orderId) => {
	const res = await OrderModel.includes([{order_items: 'product'}]).find(orderId);
	return res.data;
};

export {
	getAllOrders,
	getOrdersForMarketplace,
	getFilterOrdersForMarketplace,
	searchOrdersByNumber,
	getOrder
}