import { useDropzone } from "react-dropzone";
import './dropZoneUploader.scss'

const DropZoneUploader = ({
	preview,
	acceptPreviewHandler,
	className,
	text = 'Прикрепите файл',
}) => {
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: acceptPreviewHandler,
		maxFiles: 1,
	});

	return (
		<section className={`DropZoneRoot ${className}`}  {...getRootProps()}>
			{preview ? (
				<div {...getRootProps({ className: "dropzone" })}>
					<img
						src={typeof preview === "string" ? preview : URL.createObjectURL(preview.file)}
						alt=''
					/>
				</div>
			) : (
				<div {...getRootProps({ className: "dropzone" })}>
					<input {...getInputProps()} />
					<b>{text}</b>
				</div>
			)}
		</section>
	)
};

export default DropZoneUploader;