import MarketplaceModel from "../models/marketplaceModel";

const getMarketplaces = async () => {
	const res = await MarketplaceModel.includes(['owner', 'city']).all();
	return res.data;
};

const getUserMarketplaces = async (userId) => {
	const res = await MarketplaceModel.includes(['owner'])
	.where({ownerId: userId})
	.all();
	return res.data;
};

const getMarketplace = async (marketPlaceId) => {
	const res = await MarketplaceModel.includes(['owner'])
	.find(marketPlaceId);
	return res.data;
};

export {
	getMarketplaces,
	getUserMarketplaces,
	getMarketplace
}