import {useEffect, useState} from 'react';
import {Button, Form, InputGroup} from "react-bootstrap";
import MarketplaceModel from "../../../models/marketplaceModel";
import {getAllCities} from '../../../api/cityAPI';
import MerchantPageWrapper from "../../../components/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {attachHandler, uploadHandler} from '../../../helpers/directUpload';

const CreateMarketplacePage = () => {
	const [cities, setCities] = useState([]);
	const [logo, setLogo] = useState('');
	const [background, setBackground] = useState('');
	const [mainImage, setMainImage] = useState('');
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		status: 'draft',
		city: ''
	});

	useEffect(() => {
		getAllCities().then(
			(res) => setCities(res)
		)
	}, []);

	const changeHandler = (name, value) => {
		setFormData(p => ({...p, [name]: value}))
	};

	const attachLogoHandler = (file) => {
		attachHandler(file, (f, c) => setLogo({ file: f, checkSum: c }));
	};

	const attachBackgroundHandler = (file) => {
		attachHandler(file, (f, c) => setBackground({ file: f, checkSum: c }));
	};

	const attachMainHandler = (file) => {
		attachHandler(file, (f, c) => setMainImage({ file: f, checkSum: c }));
	};

	const submitHandler = async () => {
		const currentMarketplace = new MarketplaceModel();

		currentMarketplace.unverifiedChanges = {
			name: formData.name,
			description: formData.description
		};
		currentMarketplace.city = cities.find(c => c.id === formData.city);

		if (logo?.file) {
			currentMarketplace.unverifiedLogo = logo && (await uploadHandler(logo));
		}

		if (background?.file) {
			currentMarketplace.unverifiedCatalogBackground = background && (await uploadHandler(background));
		}

		if (mainImage?.file) {
			currentMarketplace.unverifiedImage = mainImage && (await uploadHandler(mainImage));
		}

		await currentMarketplace.save({ with: "city.id"})
		.then(async () => {
			window.location.pathname = '/merchant';
		})
	};

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper lightVersion>
				<div>
					<h1 className="marginBottom20">Создание нового бизнеса</h1>
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon1">Название (обязательное поле)</InputGroup.Text>
						<Form.Control
							placeholder="Название"
							aria-label="Название"
							aria-describedby="basic-addon1"
							value={formData.name}
							onChange={e => changeHandler('name', e.target.value)}
						/>
					</InputGroup>
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon2">Описание (обязательное поле)</InputGroup.Text>
						<Form.Control
							placeholder="Описание"
							aria-label="Описание"
							aria-describedby="basic-addon2"
							value={formData.description}
							onChange={e => changeHandler('description', e.target.value)}
						/>
					</InputGroup>
					<Form.Select
						className="marginBottom20"
						value={formData.city}
						onChange={(c) => changeHandler('city', c.target.value)}
					>
						<option>Выберите город</option>
						{cities.map(city => (
							<option key={city.name} value={city.id}>{city.name}</option>
						))}
					</Form.Select>
					<DropZoneUploader
						preview={logo}
						acceptPreviewHandler={(f) => {
							if (f) attachLogoHandler(f[0]);
						}}
						className="marginBottom20"
						text="Прикрепите логотип вашего бизнеса"
					/>
					<DropZoneUploader
						preview={background}
						acceptPreviewHandler={(f) => {
							if (f) attachBackgroundHandler(f[0]);
						}}
						className="marginBottom20"
						text="Прикрепите фон для вашего каталога"
					/>
					<DropZoneUploader
						preview={mainImage}
						acceptPreviewHandler={(f) => {
							if (f) attachMainHandler(f[0]);
						}}
						className="marginBottom20"
						text="Прикрепите ваше основное изображение"
					/>
					<Button
						variant="dark"
						style={{display: 'block', width: '100%'}}
						onClick={() => submitHandler()}
						className="marginBottom20"
						disabled={!formData.name || !formData.description || !formData.city}
					>Сохранить</Button>
				</div>
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default CreateMarketplacePage